import React, {useEffect, useState, Fragment} from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../http-common";

const SideBarLarge = () => {
    let accountType = localStorage.getItem('user-type');
    const postlogout = () =>{
        localStorage.removeItem('auth-token');
        localStorage.removeItem('delox-user');
        localStorage.removeItem('user-type');
        localStorage.removeItem('binaryfair-account-histories');
        localStorage.removeItem('binaryfair-wallet-balances'); 
        window.location.href = '/';
                
    }
    
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    const switchTheme = () => {
        const modeEvent = new Event('themeChanged');
        window.dispatchEvent(modeEvent);
    }
    const hideNavigation = () => {
        var togglerFooter = document.querySelector('#footer-toggle');
        if(togglerFooter){
            togglerFooter.click();
        }
    }

    const switchAccount = () =>{
        if (accountType === 'admin') {
            localStorage.setItem('user-type', 'normal');
        }else{
            localStorage.setItem('user-type', 'admin');
        }
       return window.location.href= '/';
    }

    useEffect(()=>{
        window.addEventListener('profileUploaded', function (e) {
            let localUser = localStorage.getItem('delox-user');
            if (localUser != null) {
                localUser = JSON.parse(localUser);
                user = localUser;
            }
        }, false);
    })   

  return (
    <div  className="">
        {/* <!-- menu-size will be the dimension of your menu. If you set it to smaller than your content it will scroll--> */}
        <div className="menu-size" style={{width: "200px"}}>
            {/* <!-- Menu Title--> */}
            <div className="pt-3">
                <div className="page-title sidebar-title d-flex">
                    <div className="align-self-center me-auto">
                        <p className="color-highlight">Welcome</p>
                        <div className='font-16'>{user.name}</div>
                    </div>
                    <div className="align-self-center ms-auto">
                        <Link to="/"
                        data-bs-toggle="dropdown"
                        className="icon gradient-green shadow-bg shadow-bg-s rounded-m">
                            <img src={user.profile} width="45" className="rounded-m" alt="img"/>
                        </Link>
                        {/* <!-- Menu Title Dropdown Menu--> */}
                        <div className="dropdown-menu">
                            <div className="card card-style shadow-m mt-1 me-1">
                                <div className="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1">
                                   
                                    <Link to="/profile" className="list-group-item">
                                        <i className="has-bg gradient-yellow shadow-bg shadow-bg-xs color-white rounded-xs bi bi-person-circle"></i>
                                        <strong className="font-13">Account</strong>
                                    </Link>
                                    <Link to="/" className="list-group-item">
                                        <i className="has-bg gradient-red shadow-bg shadow-bg-xs color-white rounded-xs bi bi-power"></i>
                                        <strong className="font-13">Log Out</strong>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins mb-3 opacity-50"></div>

                {/* <!-- Main Menu List--> */}
                <div className="list-group list-custom list-menu-large">
                    <Link to="/" id="lome-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-house-fill"></i>
                        <div>Home</div>
                        
                    </Link>
                    {accountType === 'admin' ?
                    <Fragment>
                    <Link to="/admin-investments" id="loan-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-alarm"></i>
                        <div>Users Investments</div>
                    </Link>

                    <Link to="/admin-withdrawals" id="lending-link" data-submenu="sub1" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-wallet2"></i>
                        <div>Users Withdrawal</div>
                        
                    </Link>
                    
                    <Link to="/admin-referrals" id="wallet-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-people"></i>
                        <div>Users Referral</div>
                    </Link> 

                    <Link to="/admin-transfers" id="wallet-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-people"></i>
                        <div>Users Transfers</div>
                        
                    </Link>  

                    

                    <Link to="/list-users" id="repayment-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-people"></i>
                        <div>Users List</div>
                        
                    </Link> 

                    <Link to="/plans" id="repayment-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-alarm"></i>
                        <div>Investment Plans</div>                        
                    </Link>

                    <Link to="/wallets" id="repayment-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-wallet2"></i>
                        <div>Payment Wallets</div>                        
                    </Link> 
                    <Link to="/admin-mail" id="repayment-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-envelope-heart-fill"></i>
                        <div>Send Mail</div>                        
                    </Link> 
                    
                    </Fragment>
                    :
                    <Fragment>
                    <Link to="/investment" id="loan-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-bar-chart-line"></i>
                        <div>Investment</div>
                    </Link>

                    

                    <Link to="/withdrawal" id="lending-link" data-submenu="sub1" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-download"></i>
                        <div>Withdrawal</div>
                        
                    </Link>

                    <Link to="/referral" id="wallet-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-people"></i>
                        <div>Referral</div>
                    </Link>  

                    <Link to="/confirm-pyments" id="loan-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-credit-card-2-front"></i>
                        <div>Payments</div>
                    </Link>

                    <Link to="/reinvestment" id="loan-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs  bi-server"></i>
                        <div>Reinvestment</div>
                        
                    </Link>

                    <Link to="/transfer" id="lending-link" data-submenu="sub1" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-box-arrow-right"></i>
                        <div>Transfers</div>
                    </Link>

                    <Link to="/transfer-pin" id="lending-link" data-submenu="sub1" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-key"></i>
                        <div>Transfer Pin</div>
                    </Link>

                    {/* <Link to="/withdrawal" id="lending-link" data-submenu="sub1" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-wallet2"></i>
                        <div>Withdrawal</div>
                        
                    </Link> */}
                    
                    

                    <Link to="/market" id="repayment-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-bar-chart-steps"></i>
                        <div>Market</div>
                    </Link>  

                    <Link to="/settings" id="repayment-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-gear"></i>
                        <div>Settings</div>
                        
                    </Link>
                    </Fragment>
                    }
                    

                    <a href="/#" className="list-group-item" data-toggle-theme data-trigger-switch="switch-1">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-lightbulb-fill"></i>
                        <div>Dark Mode</div>
                        <div className="form-switch ios-switch switch-green switch-s me-2">
                            <input onChange={switchTheme} type="checkbox" data-toggle-theme className="ios-input" id="switch-1"/>
                            <label className="custom-control-label" htmlFor="switch-1"></label>
                        </div>
                    </a>

                    {user.roles.length > 0 &&
                    <a href="/#" onClick={switchAccount} className="list-group-item"  data-trigger-switch="switch-2">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-person-bounding-box"></i>
                        <div>{accountType === 'admin' ? <strong className='font-14'>Switch to user</strong> : <strong className='font-14'>Switch to admin</strong>}</div>
                        <div className="form-switch ios-switch switch-green switch-s me-2">
                            <input type="checkbox"  className="ios-input" id="switch-2"/>
                            <label className="custom-control-label" htmlFor="switch-2"></label>
                        </div>
                    </a>
                    }
                </div>

                <div className="divider divider-margins mb-3 opacity-50"></div>

                {/* <!-- Quick Actions Icons--> */}
                <div className="content pt-1">
                    <div className="d-flex text-center">
                        <div className="me-auto">
                            <Link to="/invest"  className="icon icon-s rounded-s gradient-red shadow-bg shadow-bg-xs"><i className="font-16 color-white bi bi-arrow-up"></i></Link>
                            <h6 className="font-11 font-400 opacity-70 mb-n1 pt-2">Invest</h6>
                        </div>
                        <div className="m-auto">
                            <Link to="/withdraw" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-s rounded-s gradient-green shadow-bg shadow-bg-xs"><i className="font-16 color-white bi bi-arrow-down"></i></Link>
                            <h6 className="font-11 font-400 opacity-70 mb-n1 pt-2">Withdraw</h6>
                        </div>
                        <div className="ms-auto">
                            <Link to="/referral" className="icon icon-s rounded-s gradient-green shadow-bg shadow-bg-xs"><i className="font-16 color-white bi bi-arrow-left-right"></i></Link>
                            <h6 className="font-11 font-400 opacity-70 mb-n1 pt-2">Referral</h6>
                        </div>
                    </div>
                </div>


                <div className="divider divider-margins opacity-50"></div>

                {/* <!-- Useful Links--> */}
                <h6 className="opacity-40 px-3 mt-n2 mb-0">Useful Links</h6>
                <div className="list-group list-custom list-menu-small">
                    
                    <Link to="/profile" className="list-group-item">
                        <i className="bi bi-person-circle opacity-20 font-16"></i>
                        <div>Profile</div>
                    </Link>
                    <Link to="/settings" className="list-group-item">
                        <i className="bi bi-gear opacity-20 font-16"></i>
                        <div>Settings</div>
                    </Link>
                    
                    <Link to="/" onClick={postlogout} className="list-group-item">
                        <i className="bi bi-bar-chart-fill opacity-20 font-16"></i>
                        <div>Log Out</div>
                    </Link>
                </div>

                <div className="divider divider-margins opacity-50"></div>
                {/* <!-- Menu Copyright --> */}
                <p className="px-3 font-9 opacity-30 color-theme mt-n3">&copy; Beacon Mine Limited<i className="bi bi-heart-fill color-red-dark px-1"></i>beacon-mines.com</p>
            </div>
        </div>
    </div>
  )
}

export default SideBarLarge